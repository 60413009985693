.item {
    .card {
        border: none;
        border-radius: 0;
        box-shadow: $box-shadow;
        &-body {
            padding: ($grid-gutter-width * 1.16) $grid-gutter-width;
            .card-caption {
                @extend .d-flex;
                @extend .flex-column;
                .card-title-link {
                    order: 2;
                    color: $icky-green;
                    &:hover {
                        .card-title {
                            color: darken($color: $icky-green, $amount: 20%) !important; 
                        }
                    }
                    .card-title {
                        position: relative;
                        width: auto;
                        &::after {
                            width: 85%;
                        }
                        font: {
                            family: $font-family-exo;
                            weight: $font-weight-regular;
                        }
                    }
                }
                .card-subtitle {
                    order: 1;
                    color: $secondary;
                    margin-bottom: $grid-gutter-width / 1.2;
                    font: {
                        size: $font-size-base_sm;
                        family: $font-family-exo;
                        weight: $font-weight-bold;
                    }
                }
                .card-description {
                    order: 3;
                    margin-top: $grid-gutter-width / 3;
                    &-content {
                        color: $zeus;
                        font-size: $font-size-base * 1.125;
                        padding-bottom: $grid-gutter-width / 3;
                    }
                    .list {
                        &-item {
                            color: $primary;
                            .icon {
                                font: {
                                    size: $font-size-base;
                                    weight: $font-weight-bold;
                                }
                            }
                            .label {
                                font: {
                                    family:$font-family-exo;
                                    size: $font-size-base_sm;
                                    weight: $font-weight-bold;
                                }
                            }
                        }
                    }
                }
            }
            .card-buttons {
                margin-top: $grid-gutter-width * 1.33;
                .card-btn {
                    @extend .btn-secondary;
                }
            }
        }
    }
}
.collection.grid {
    .grid-items {
        z-index: 1;
        position: relative;
        .item {
            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }
        }
    }
}
