.contentblock.owl-carousel {
	position: relative;
	&::before {
		border-top: 4px solid $primary;
		content: "";
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		bottom: 0;
		width: 95%;
		z-index: -1;
	}
	&::before {
		border-top: 4px solid $primary;
		content: "";
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		bottom: 0;
		width: 95%;
	}
	.owl-stage-outer {
		.owl-stage {
			.owl-item {
				margin-bottom: $grid-gutter-width;
			}
		}
	}
	.owl-nav {
		display: block !important;
		opacity: 1;
		pointer-events: all;
		.owl-prev {
			left: -5px;
			@include media-breakpoint-up(xl) {
				left: -50px;
			}
			i {
				position: relative;
				&::after {
					border-top: 4px solid $primary;
					content: "";
					position: absolute;
					top: 50%;
					left: 23px;
					right: 0;
					bottom: 0;
					width: 65%;
					@include media-breakpoint-up(xl) {
						left: 45px;
						width: 70%;
					}
				}
			}
		}
		.owl-next {
			i {
				position: relative;
				&::after {
					border-top: 4px solid $primary;
					content: "";
					position: absolute;
					top: 50%;
					left: -12px;
					right: 0;
					bottom: 0;
					width: 60%;
					@include media-breakpoint-up(xl) {
						left: -30px;
						width: 70%;
					}
				}
			}
		}
		.owl-prev,
		.owl-next {
			width: 20px;
			@include media-breakpoint-up(xl) {
				width: 0;
			}
		}
		button {
			i {
				font: {
					size: $h3-font-size;
					weight: $font-weight-regular;
				}
				@include media-breakpoint-up(xl) {
					font-size: $font-size-base * 3;
				}
			}
		}
	}
	.owl-dots {
		display: none;
	}
}
.content-section.stay-info {
	.contentblock.owl-carousel {
		&::before {
			top: 40%;
		}
		.owl-nav {
			bottom: 60%;
		}
	}
}
.accomodation {
	.contentblock.owl-carousel {
		.owl-stage-outer {
			.owl-stage {
				.owl-item {
					.item {
						.card.dark {
							width: 100%;

							.card-image {
								display: none;
							}
							.card-body {
								background-color: $secondary;
								.card-caption {
									.card-title,.card-description-content,.card-description .list-item {
										color: white !important;
									}
								}
								.card-buttons {
									.card-btn {
										border-color: 1px solid $white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
