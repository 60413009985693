// custom-list
ul.custom-list-extra {
    list-style-type: none;
    padding-left: 20px;
    li {
        position: relative;
        font-size: $font-size-base * 1.125;
        margin-bottom: 5px;
        color: $black;
        &::before {
            content: "\f111";
            position: absolute;
            top: 30%;
            left: -18px;
            font-family: $font-awesome;
            font-size: $font-size-base / 2;
            color: $secondary;
            font-weight: $font-weight-bold;
        }
    }
}
ol {
    padding-left: 0;
    list-style: none;
    counter-reset: li;
    li {
        font-size: $font-size-base * 1.125;
        margin-bottom: 5px;
        color: $black;
        &::before {
            counter-increment: li;
            content: counter(li) ".";
            margin-right: 10px;
            margin-bottom: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: $black;
            font-weight: $font-weight-regular;
        }
    }
}
ul.custom-list {
    padding-left: 0 !important;
    li {
        font-size: $font-size-base * 1.125;
        margin-bottom: 5px;
        color: $black;
        padding-left: 20px;

        &::before {
            content: "\f00c" !important;
            font-family: $font-awesome;
            font-size: $font-size-base_sm !important;
            left: 0 !important;
            top: 10%;
        }
    }
}
.custom-list-extra {
    margin-bottom: 30px;
}
