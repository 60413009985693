> footer > section {
	&.footer-content {
		padding: ($grid-gutter-width * 2.5) 0;
		background-color: $secondary;
		.container-one-column {
			.container-holder {
				text-align: center;
				.column {
					.page-block.logo {
						margin-bottom: $grid-gutter-width / 1.5;
					}
					.footer-text {
						&:nth-child(2) {
							p {
								color: white;
								max-width: 220px;
								margin: auto;
								text-align: center;
								margin-bottom: $grid-gutter-width * 2;
							}
						}
						p {
							margin-bottom: 0;
							a {
								color: white;
								text-decoration: none;
								font-size: $font-size-base * 1.125;
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
					ul.vocabulary-term-list {
						margin: ($grid-gutter-width / 1.5) auto auto;
						li {
							a {
								i {
									color: white;
									font-size: $font-size-base * 2.25;
								}
								span {
									display: none;
								}
								&:hover {
									i {
										color: darken($color: white, $amount: 20%);
									}
								}
							}
							&:not(:last-child) {
								margin-right: $grid-gutter-width;
							}
						}
					}
				}
			}
		}
	}
	&.footer-socket {
		padding: 1vh 0;
		background-color: $secondary;
		.container-one-column {
			display: flex;
			justify-content: center;
			.container-holder {
				.column {
					.footer-link {
						.list {
							&-item {
								a {
									color: white;
									font-size: $font-size-base_xxs;
									letter-spacing: 1.2px;
									text-decoration: none;
									&:hover {
										text-decoration: underline;
									}
								}
								&:not(:last-child) {
									@include media-breakpoint-up(sm) {
										margin-right: $grid-gutter-width / 1.5;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
