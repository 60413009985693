.header {
	z-index: 11;

	.container-one-column {
		.container-holder {
			.column {
				background-color: $secondary;
				padding: ($grid-gutter-width / 2) 0 ($grid-gutter-width * 1.16);
				height: 100% !important;
				align-items: flex-start !important;
				@include media-breakpoint-up(xl) {
					padding: ($grid-gutter-width / 6) ($grid-gutter-width) ($grid-gutter-width / 1.2);
					align-items: center !important;
				}

				.menu-toggle {
					.navbar-toggler {
						&[aria-expanded="true"] {
							.fa-bars:before {
								content: "\F00D" !important;
								color: white;
							}
						}

						&-icon {
							width: 24px;
							height: 27px;

							i {
								&::before {
									content: url('/images/Hamburger_menu.svg') !important;
								}
							}
						}
					}
				}

				.navbar-collapse {
					.navbar-nav {
						.nav-item {
							.nav-link {
								color: $white;
								font-family: $font-family-exo;
								font-size: $font-size-base_sm;
								font-weight: $font-weight-regular;
								padding: ($spacer / 2) $spacer;
								@include media-breakpoint-up(xl) {
									padding: ($spacer * 1.5) $spacer;
								}

								&:hover {
									color: darken($color: $white, $amount: 15%);
								}
							}

							.dropdown-toggle {
								display: flex;
								align-items: center;
								justify-content: space-between;
								position: relative;
								@include media-breakpoint-down(lg) {
									&[aria-expanded="true"] {
										&::after {
											content: "\f068";
										}
									}
								}

								&::after {
									content: "\F067";
									position: absolute;
									right: 10px;
									display: block;
									font-family: $font-awesome;
									font-weight: $font-weight-regular;
									font-size: $font-size-base_sm;
									border: none;
									margin-right: 3px;
								}

								@include media-breakpoint-up(xl) {
									&::after {
										content: "\f078";
										position: absolute;
										display: block;
										top: 50%;
										right: 0;
										font-family: $font-awesome;
										font-weight: $font-weight-regular;
										transform: translateY(-50%);
										font-size: $font-size-base_xxs;
										height: 16px;
										border: none !important;
									}
								}
							}

							.dropdown-menu {
								background: transparent;
								border: none;
								position: static !important;
								list-style: none;
								@include media-breakpoint-down(lg) {
									transform: none !important;
								}

								@include media-breakpoint-up(xl) {
									border: 1px solid $base-1;
									border-radius: 6px;
									box-shadow: $box-shadow;
									position: absolute !important;
									&:before,
									&:after {
										content: "";
										display: block;
										height: 0;
										left: 50%;
										position: absolute;
										width: 0;
									}
									&:before {
										border-left: 8px solid transparent;
										border-right: 8px solid transparent;
										border-bottom: 6px solid $base-1;
										margin-left: -5px;
										top: -6px;
										z-index: 4;
									}
									&:after {
										border-left: 6px solid transparent;
										border-right: 6px solid transparent;
										border-bottom: 5px solid $secondary;
										margin-left: -3px;
										top: -5px;
										z-index: 5;
									}
								}

								&.show {
									@include media-breakpoint-up(xl) {
										background: $secondary;
									}

									.nav-item {
										&.last {
											padding: 0;
										}
									}
								}

								li {
									a {
										font-size: $font-size-base_xs;
										padding: ($spacer / 2) $spacer;
									}
								}
							}
						}
					}
				}

				ul.zoek-en-boek-button {
					margin-bottom: 0;
					position: absolute;
					right: 0;
					top: 90px;
					@include media-breakpoint-up(xl) {
						position: relative;
						top: 0;
					}

					li {
						a {
							@extend .btn;
							@extend .btn-primary;
							position: relative;
							@include media-breakpoint-down(lg) {
								padding: ($grid-gutter-width / 7.5) ($grid-gutter-width / 2) !important;
							}

							&::after {
								position: absolute;
								content: "";
								display: block;
								top: 70%;
								right: 10px;
								width: 39px;
								height: 39px;
								background: url("/images/arrow.svg") no-repeat bottom / contain;
							}
						}
					}
				}

				.page-block.site-switcher {
					.navbar-nav {
						.nav-item {
							.nav-link {
								opacity: 0.75;

								img {
									width: 21px;
									height: 21px;
									border: 1px solid white;
									border-radius: 50%;
								}

								&:hover {
									opacity: 1;

									img {
										box-shadow: $box-shadow;
									}
								}
							}

							&.active {
								.nav-link {
									opacity: 1;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-right: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	// responsive custom styling
	.page-block.menu-toggle {
		min-width: 0;
	}

	.page-block.logo {
		min-width: 0;
		margin: 0;
	}

	.page-block.site-switcher {
		margin: 0;
		flex-grow: 1;
	}

	// responsive mobile header order.
	.page-block.menu-toggle {
		order: 0;
	}

	.page-block.logo {
		order: 1;
	}

	.page-block.site-switcher {
		order: 2;
	}

	.page-block.menu {
		order: 3;
		margin-top: $grid-gutter-width * 2.33;
		@include media-breakpoint-up(xl) {
			margin-top: 0;
		}
	}

	// Desktop resets
	@include media-breakpoint-up(xl) {
		.page-block.menu-toggle {
			order: unset;
		}
		.page-block.logo {
			order: unset;
		}
		.page-block.site-switcher {
			order: unset;
		}
		.page-block.menu {
			order: unset;
		}

		.page-block.site-switcher {
			display: flex;
			justify-content: flex-end;
			margin-left: 25px;
			flex-grow: 0;
		}

		.page-block.menu-toggle {
			margin: 0 0 0 auto;
		}
	}

}

&.home {
	.header {
		.container-one-column {
			.container-holder {
				.column {
					.navbar-collapse {
						&::after {
							@include media-breakpoint-up(xl) {
								content: url("/images/logo-green.svg");
								position: absolute;
								top: 52px;
								left: 50%;
								transform: translateX(-50%);
							}
						}
					}
				}
			}
		}

		&.sticky {
			.container-one-column {
				.container-holder {
					.column {
						.navbar-collapse {
							&::after {
								content: none;
							}
						}
					}
				}
			}
		}
	}
}
