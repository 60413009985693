// variables loaded before loading vendor variables, used to prepare vendor variables like bootstrap

// font
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;700&display=swap");
$font-family-exo: "Exo", sans-serif;

@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");
$font-family-special-elite: "Special Elite", cursive;

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400&display=swap");
$font-family-source-sans-pro: "Source Sans Pro", sans-serif;
$font-family-base: $font-family-source-sans-pro;

$font-awesome: "Font Awesome 5 Pro";

// build options
$use-sticky-header: true;

// colors
$primary: #d1603d;
$secondary: #4f5d2f;
$icky-green: #7db624;
$tistel: #ceb4ca;
$desert-storm: #f9f9ee;
$smokey-grey: #707070;
$curious-blue: #1b98e0;
$zeus: #262A20;

// matrix-colors
$matrix-color-primary: #d1603d;
$matrix-color-secondary: #4f5d2f;
$matrix-color-tertiary: #488DDB;
$matrix-color-quaternary: #044389;

// typography
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-style-italic: italic;
$font-style-base: normal !default;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 1.875 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base_sm !default;
$h5-font-size: $font-size-base_sm !default;
$h6-font-size:  $font-size-base_sm !default;

$link-color: $icky-green;
$link-color--hover: darken($link-color, 15%) !default;

body {
  &.default {
    overflow-x: hidden;
  }
}

/*
$body-bg: #FFF !default;
$body-color: #000 !default;
$body-color--dark: #fff !default;
$link-color: $primary !default;
$link-color--hover: darken($link-color, 15%) !default;

// font
$font-family-base: 'Helvetica', sans-serif !default;

// typography
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-style-italic: italic;
$font-style-base: normal !default;

$headings-font-family: $font-family-base !default;
$headings-font-style: $font-style-base !default;
$headings-font-weight: $font-weight-bold !default;
$headings-color: $body-color !default;

$color-h1: $body-color !default;
$color-h2: $body-color !default;
$color-h3: $body-color !default;
$color-h4: $body-color !default;
$color-h5: $body-color !default;
$color-h6: $body-color !default;

// grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1500px,
  xxxl: 1820px
) !default;

$grid-breakpoints-small: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths-small: (
  xs: 0,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
  xxl: 1300px,
  xxxl: 1600px
) !default;

// header
$header-logo-height: 50px !default;
$header-logo-height--lg: 80px !default;
$header-logo-margin: 20px !default;

// card
$card-bg--dark: #000 !default;

// carousel
$carousel-min_height: 35vh !default;
$carousel-min_height--lg: 70vh !default;
*/
