.eyecatcher {
    margin-top: -($spacer);
    .logo-container {
        .container-holder {
            margin: 0;
           @include media-breakpoint-up (xl) {
               margin:  -15px;
           }
            .column {
                .logo {
                    position: relative;
                    padding-bottom: 0;
                    display: none;
                    @include media-breakpoint-up(xl) {
                        padding-bottom: 125px;
                        display: block;
                    }
                    &::before {
                        content: none;
                        @include media-breakpoint-up(xl) {
                            content: "";
                            position: absolute;
                            display: block;
                            width: 100%;
                            background-color: $desert-storm;
                            height: 125px;
                            z-index: 1;
                        }
                    }
                    img {
                        position: absolute;
                        left: 50%;
                        top: -40px;
                        transform: translateX(-50%);
                        z-index: 11;
                        display: none;
                    }
                }
            }
        }
    }
    &-owl-carousel {
        .owl-stage-outer {
            .owl-stage {
                .owl-item {
                    .item {
                        .owl-container {
                            .owl-caption {
                                position: absolute;
                                bottom: 80px;
                                left: 50%;
                                transform: translateX(-50%);
                                .owl-title {
                                    color: white;
                                    font-size: $h1-font-size;
                                    font-family: $font-family-exo;
                                    font-weight: $font-weight-regular;
                                }
                                .owl-subtitle,
                                .owl-description,
                                .owl-btn {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .owl-dots,
        .owl-nav {
            display: none;
        }
    }
}
