// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
    @extend .clearfix;
}
.search-book .tommy-zeb-wrapper * {
    font-family: $font-family-base !important;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-left {
    background: $secondary;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
    background: $secondary;
    display: flex;
    flex-direction: row;
    padding: 35px 25px;
    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }
}
.search-book .tommy-zeb-wrapper .tommy-zeb-left h1 {
    display: none;
}
.search-book .tommy-zeb-wrapper * h2 {
    font-size: 18px;
    font-weight: 700;
    font-family: $font-family-exo !important;
    color: white;
}
.search-book .tommy-zeb-result-details ul {
    list-style-type: none;
    color: white;
    @extend ul.custom-list-extra;
    margin-bottom: $grid-gutter-width;
    li {
        font-size: $font-size-base;
        margin-bottom: 10px;
        color: white;
        font {
            font-size: $font-size-base !important;
        }
        &::before {
            color: white;
        }
    }
}
.search-book .tommy-zeb-result-details span a {
    @extend .btn;
    @extend .btn-secondary;
    border-color: $white !important;
}
.search-book .tommy-zeb-results {
    @include media-breakpoint-up(lg) {
        margin-top: -22px;
    }
    .tommy-zeb-results-label {
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            transform: translateY(-20px);
            margin-bottom: 0;
        }
    }
}
.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
    margin-top: 0 !important;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
    @extend .btn-primary;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    font-family: $font-family-exo !important;
    color: white;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
    color: $white;
    font-weight: 700;
}
.search-book .tommy-zeb-wrapper .tommy-zeb-left {
    @include media-breakpoint-up(sm) {
        @include make-col(4);
        width: 33.33333%;
    }
}
.search-book .tommy-zeb-wrapper .tommy-zeb-right {
    padding: 20px 0 0 !important;
    @include media-breakpoint-up(sm) {
        @include make-col(8);
        width: 66.66667%;
        padding: 0 0 0 20px !important;
    }
}
@media (min-width: 1200px) {
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
        max-width: 250px;
    }
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
        width: 520px;
    }
}
@media (min-width: 980px) and (max-width: 1199px) {
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
        margin-bottom: 15px;
    }
}
@media (min-width: 768px) and (max-width: 979px) {
    .search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
        margin-right: 0;
    }
}
