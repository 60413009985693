/* 	bg-light/bg-dark
    ========================================================================== */
section.bg-light,
section.bg-dark {
	margin: 0;
	padding: 6vh 0;
}

/* 	lead-section
    ========================================================================== */
&.home {
	.lead-section {
		.page-block {
			&.wysiwyg {
				p {
					&:last-of-type {
						margin-bottom: 0 !important;
					}
				}
			}
		}
	}
}
.lead-section {
	background-color: $icky-green;
	padding: ($grid-gutter-width * 1.83) 0;
	position: relative;
	&::before {
		content: "";
		background: url("/images/leaf.svg") repeat !important;
		background-size: 100%;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		opacity: 0.3;
	}

	.container-one-column {
		.container-holder {
			max-width: 810px;
			margin: 0 auto;
			text-align: center;
			.column {
				.page-block {
					h1 {
						position: relative;
						color: white;
						&::after {
							content: "";
							position: absolute;
							background: url("/images/beeldmerk.svg") no-repeat top/cover;
							width: 83px;
							height: 40px;
							bottom: -50px;
							left: 50%;
							transform: translateX(-50%);
						}
					}
					h3 {
						position: relative;
						color: $secondary !important;
						font-family: $font-family-special-elite;
						font-weight: $font-weight-regular;
						margin-top: $grid-gutter-width * 2;
						width: max-content;

						&::after {
							position: absolute;
							content: "";
							display: block;
							top: 32px;
							left: 0;
							width: 187px;
							height: 96px;
							background: url("/images/down_arrow.svg") no-repeat top/contain;
							@include media-breakpoint-up(sm) {
								top: -20px;
								left: 100%;
							}
						}
					}
					h5 {
						margin-bottom: 0;
						line-height: 1.2;
					}
					&.wysiwyg {
						margin-top: $grid-gutter-width * 2.5;
						p {
							color: white;
						}
					}
				}
			}
		}
	}

	/* bundles */
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}
	.info {
		.page-block {
			h5 {
				color: $secondary;
				margin-bottom: 0;
			}
			h1 {
				position: relative;
				&::after {
					@extend .border-bottom;
					border-color: $secondary !important;
					bottom: 0;
				}
			}
			h1,
			p {
				color: $white;
			}
		}
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;
			li {
				color: $secondary;
				margin-right: $grid-gutter-width / 2;
				font: {
					family: $font-family-exo;
					size: $font-size-base_sm;
					weight: $font-weight-bold;
				}
			}
			.icon {
				margin-right: 5px;
			}
		}
	}
}

/* 	content-section
    ========================================================================== */
.content-section.accomodation {
	position: relative;
	padding: 0 0 $grid-gutter-width * 3 0;
	background-color: $icky-green;
	&::before {
		content: "";
		background: url("/images/leaf.svg") repeat !important;
		background-size: 100%;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		opacity: 0.3;
	}
	&::after {
		content: "";
		position: absolute;
		display: block;
		bottom: 0;
		left: 0;
		right: 0;
		background: $desert-storm;
		height: 700px;
	}
	.container-one-column {
		.container-holder {
			.column {
				.page-block.title {
					padding: ($grid-gutter-width) 0 ($grid-gutter-width * 1.66);
					@include media-breakpoint-up(sm) {
						padding: ($grid-gutter-width * 2.33) 0 ($grid-gutter-width * 1.66);
					}
					h3 {
						color: $secondary !important;
						font-family: $font-family-special-elite;
						font-weight: $font-weight-regular;
						position: relative;
						&::after {
							position: absolute;
							content: "";
							display: block;
							top: 32px;
							left: 0;
							width: 187px;
							height: 96px;
							background: url("/images/down_arrow.svg") no-repeat top/contain;
							@include media-breakpoint-up(xl) {
								top: -10px;
								right: 61%;
								left: auto;
							}
						}
					}
				}
				a.page-block.link {
					@extend .btn;
					@extend .btn-primary;
					position: relative;
					width: 100%;
					margin: auto;
					z-index: 1;
				}
			}
		}
	}
}

/* 	image-section
    ========================================================================== */
.image-section {
	.container-two-columns {
		.container-holder {
			position: relative;
			.column {
				&.one {
					order: 2;
					background-color: $secondary;
					padding: ($grid-gutter-width * 2) ($grid-gutter-width * 1.66);
					@include media-breakpoint-up(sm) {
						position: absolute;
						right: 0;
						height: 100%;
						width: 350px;
						flex: 0 0 350px;
						left: 0;
						z-index: 2;
						padding: ($grid-gutter-width * 5) ($grid-gutter-width) ($grid-gutter-width * 3);
						order: 1;
					}
					.page-block {
						h2,
						p {
							color: white;
						}
						h2 {
							position: relative;
							margin-bottom: $grid-gutter-width / 2;
							font: {
								size: $h3-font-size;
								family: $font-family-exo;
								weight: $font-weight-regular;
							}
							&::after {
								@extend .border-bottom;
								width: 55%;
							}
						}
						.link {
							@extend .btn;
							@extend .btn-secondary;
							border-color: $white !important;
							margin: ($grid-gutter-width * 1.33) 0 !important;
						}
					}
				}
				&.two {
					order: 1;
					padding: 0 !important;
					margin: 0;
					@include media-breakpoint-up(sm) {
						flex: 0 0 100%;
						max-width: 100%;
						margin: 0;
						padding-right: 0;
						position: relative;
						z-index: 1;
						order: 2;
					}
					.resource-image {
						img {
							height: 575px;
							width: 100%;
							object-fit: cover;
							@include media-breakpoint-up(sm) {
								height: 650px;
							}
						}
					}
				}
			}
		}
	}
}

/* 	highlight-section
    ========================================================================== */
.highlight-section {
	background-color: $desert-storm;
	padding: ($grid-gutter-width * 2.5) ($grid-gutter-width);
	@include media-breakpoint-up(sm) {
		padding: ($grid-gutter-width * 3) 0;
	}
	.container-two-columns {
		.container-holder {
			background-color: $tistel;
			padding: ($grid-gutter-width * 1.5) ($grid-gutter-width * 1.16);
			box-shadow: $box-shadow;
			margin: 0 (-($grid-gutter-width / 2)) (-($grid-gutter-width / 2)) !important;
			.column {
				&.one {
					padding: 0 !important;
					@include media-breakpoint-up(sm) {
						@include make-col(4);
					}
					.page-block {
						h2 {
							@extend h1;
							color: black;
							position: relative;
							&::after {
								@extend .border-bottom;
								border-color: black !important;
								width: 50%;
							}
						}
					}
				}
				&.two {
					padding: 0 !important;
					@include media-breakpoint-up(sm) {
						@include make-col(8);
					}
					@include media-breakpoint-up(lg) {
						padding-left: 3rem !important;
						padding-right: $grid-gutter-width / 2 !important;
					}
					p {
						.btn {
							padding: ($grid-gutter-width / 5) ($grid-gutter-width / 2.5);
							background-color: transparent;
							border-color: black;
							color: black;
							font-family: $font-family-exo;
							font-weight: $font-weight-regular;
							transition: box-shadow 0.3s;
							&:hover {
								box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
							}
						}
					}
				}
			}
		}
	}
}

.content-section.stay-info {
	background-color: $desert-storm;
	padding: 0 0 ($grid-gutter-width * 2);
	@include media-breakpoint-up(sm) {
		padding: ($grid-gutter-width) 0 ($grid-gutter-width * 3);
	}
	.container-one-column {
		text-align: center;
		&:first-of-type {
			.container-holder {
				max-width: 810px;
				margin: 0 auto;
				text-align: center;
				.column {
					.page-block {
						h1,
						p {
							color: $secondary;
						}
						h1 {
							position: relative;
							&::after {
								content: "";
								position: absolute;
								background: url("/images/beeldmerk.svg") no-repeat top/cover;
								width: 83px;
								height: 40px;
								bottom: -50px;
								left: 50%;
								transform: translateX(-50%);
							}
						}
						&.wysiwyg {
							margin-top: $grid-gutter-width * 2.5;
							p {
								margin-bottom: $grid-gutter-width * 2;
							}
						}
					}
				}
			}
		}
		.contentblock {
			.owl-stage-outer {
				.owl-stage {
					.owl-item {
						.item {
							.card {
								box-shadow: none;
								background-color: transparent;
								&-image {
									box-shadow: $box-shadow;
								}
								&-body {
									background-color: transparent;
									padding: ($grid-gutter-width * 1.16) ($grid-gutter-width) 0;
									.card-title {
										font-size: $font-size-base * 1.125;
										font-family: $font-family-special-elite;
										text-align: center;
										&::after {
											content: none;
										}
									}
									.card-buttons {
										display: none;
									}
								}
							}
						}
					}
				}
			}
			.owl-dots {
				display: none;
			}
		}
	}
}

.content-section.bg-dark {
	background-color: $icky-green !important;
	padding: ($grid-gutter-width * 1.83) 0;
	position: relative;
	&::before {
		content: "";
		background: url("/images/leaf.svg") repeat !important;
		background-size: 100%;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		opacity: 0.3;
	}
	.container-one-column {
		.container-holder {
			max-width: 810px;
			margin: 0 auto;
			text-align: center;
			.column {
				.page-block {
					h1 {
						position: relative;
						&::after {
							content: "";
							position: absolute;
							background: url("/images/beeldmerk.svg") no-repeat top/cover;
							width: 83px;
							height: 40px;
							bottom: -50px;
							left: 50%;
							transform: translateX(-50%);
						}
					}
					&.wysiwyg {
						margin-top: $grid-gutter-width * 2.5;
					}
				}
			}
		}
	}
}

/* 	content-section.facilities
    ========================================================================== */
.content-section.facilities {
	position: relative;
	padding: 0 0 $grid-gutter-width * 3 0;
	background-color: $icky-green;
	&::before {
		content: "";
		background: url("/images/leaf.svg") repeat !important;
		background-size: 100%;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		opacity: 0.3;
	}
	&::after {
		content: "";
		position: absolute;
		display: block;
		bottom: 0;
		left: 0;
		right: 0;
		background: $desert-storm;
		height: 750px;
	}
	.container-one-column {
		.container-holder {
			text-align: center;
			.column {
				a.page-block.link {
					@extend .btn;
					@extend .btn-primary;
					position: relative;
					z-index: 1;
				}
			}
		}
	}
}
/* 	bundle-overview-section

    ========================================================================== */
.lead-section {
	+ .bundle-overview-section {
		position: relative;
		padding-bottom: $grid-gutter-width * 3;
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100px;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1;
			background-color: $icky-green;
		}
		&::after {
			content: "";
			position: absolute;
			display: block;
			bottom: 0;
			left: 0;
			right: 0;
			background: $desert-storm;
			height: 100%;
		}
		.container-one-column {
			&::before {
				content: "";
				position: absolute;
				background: url("/images/leaf.svg") repeat !important;
				background-size: 100%;
				width: 100vw;
				height: 100px;
				z-index: 1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0.3;
			}
		}
	}
}
.bundle-overview-section {
	position: relative;
	padding-bottom: $grid-gutter-width * 3;
	&::after {
		content: "";
		position: absolute;
		display: block;
		bottom: 0;
		left: 0;
		right: 0;
		background: $desert-storm;
		height: 100%;
	}
}

/* 	bundle-detail-section
    ========================================================================== */
&.bundle-detail {
	.lead-section {
		padding: ($grid-gutter-width * 1.83) 0 0;
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 10%;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			background-color: $desert-storm;
			@include media-breakpoint-up(sm) {
				height: 15%;
			}
		}
	}
}
.bundle-detail-section {
	background-color: $desert-storm;
	padding: ($grid-gutter-width * 2) 0;
	.container-one-column {
		.container-holder {
			max-width: 810px;
			margin: auto;
		}
	}
}

/* 	theme-demo
 ========================================================================== */
&.theme-demo {
	.lead-section {
		padding-top: $grid-gutter-width * 5;
		background-color: $desert-storm;
		margin-top: -15px;
		.container-one-column {
			.container-holder {
				max-width: 810px;
				margin: 0 auto;
				text-align: center;
			}
		}
	}
}
.lead-section {
	.container-one-column {
		.container-holder {
			.column {
				h5 {
					font: {
						size: $font-size-base_sm;
						family: $font-family-exo;
						weight: $font-weight-bold;
					}
				}
				h1,
				h5,
				p {
					color: $secondary;
				}
			}
		}
	}
	&.bg-dark {
		background-color: $secondary !important;
		&::before {
			content: none;
		}
		.container-one-column {
			.container-holder {
				.column {
					h1,
					h5,
					p {
						color: $white !important;
					}
				}
			}
		}
	}
}

.content-section.pt-0 {
	background-color: $desert-storm;
	padding: ($grid-gutter-width * 3) 0 !important;
	.container-two-columns {
		.container-holder {
			.column {
				&.two {
					ul {
						@extend ul.custom-list-extra;
					}
					ul,
					ol {
						margin-bottom: $grid-gutter-width;
					}
					.btn-primary {
						margin: 0;
					}
				}
			}
		}
	}
	&.bg-dark {
		background-color: $secondary !important;
		margin-bottom: $grid-gutter-width * 3;
		&::before {
			content: none;
		}
		.container-two-columns {
			.container-holder {
				.column {
					&.two {
						h4 {
							color: $white;
						}
						ul {
							@extend ul.custom-list-extra;
						}
						ul,
						ol {
							margin-bottom: $grid-gutter-width;
							li {
								color: $white !important;
								&::before {
									color: $white !important;
								}
							}
						}
					}
				}
			}
		}
	}
}

.content-section {
	padding: ($grid-gutter-width * 3) 0;
	.column.one {
		.page-block.wysiwyg {
			p {
				&:nth-of-type(2) {
					a {
						text-decoration: none;
						&::before {
							font-family: $font-awesome;
							font-size: $font-size-base;
							font-weight: $font-weight-bold;
							margin-right: $grid-gutter-width / 3;
						}
						&:first-of-type {
							margin-right: $grid-gutter-width / 1.5;
							&::before {
								content: "\F095";
							}
						}
						&:last-of-type {
							&::before {
								content: "\F0E0";
							}
						}
					}
				}
			}
		}
		p {
			a {
				font-size: $font-size-base * 1.125;
			}
		}
		ul.vocabulary-term-list {
			margin-top: $grid-gutter-width / 3;
			li {
				a {
					span {
						display: none;
					}
					i {
						font-size: $h3-font-size;
					}
				}
				&:not(:last-child) {
					margin-right: $grid-gutter-width / 1.5;
				}
			}
		}
	}
	.column.two {
		.form {
			margin-top: $grid-gutter-width / 2;
		}
	}
}
// &.accommodation-category-detail,&.service-category-detail {
// 	.bundle-detail-section {
// 		background-color: $icky-green;
// 		position: relative;
// 		&::before {
// 			content: "";
// 			background: url("/images/leaf.svg") repeat !important;
// 			background-size: 100%;
// 			position: absolute;
// 			width: 100%;
// 			height: 100%;
// 			top: 0;
// 			left: 0;
// 			right: 0;
// 			opacity: 0.3;
// 		}
// 		p {
// 			color: white;
// 		}
// 	}
// }

&.service-category-detail {
	.bundle-overview-section {
		padding-top: $grid-gutter-width * 3;
	}
	.bundle-overview-section,
	.container-one-column {
		&::before {
			content: none;
		}
	}
}
