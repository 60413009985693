// headings
// h1 {
//     font-size: 36px;
//     @include media-breakpoint-up(xl) {
//         font-size: 48px;
//     }
// }
// h2 {
//     font-size: 30px;
//     @include media-breakpoint-up(xl) {
//         font-size: 36px;
//     }
// }
// h3 {
//     font-size: 24px;
// }
// h4 {
//     font-size: 16px;
// }
// h5 {
//     font-size: 14px;
// }
// h6 {
//     font-size: 12px;
// }
//border-bottom
.border-bottom {
	content: "";
	position: absolute;
	display: block;
	border-bottom: 2px solid $icky-green !important;
	width: 100%;
	bottom: -10px;
}

h1 {
	font-size: $h2-font-size;
	@include media-breakpoint-up(lg) {
		font-size: $h1-font-size;
	}
}

h1,
h2,
h3 {
	color: $secondary;
	font: {
		family: $font-family-exo;
		weight: $font-weight-regular;
	}
}

h3,
h6 {
	position: relative;
	color: $icky-green !important;

	&::after {
		@extend .border-bottom;
	}
}

h4 {
	color: $secondary;
}

h5 {
	color: $primary;
}

h6 {
	&::after {
		border-width: 1px !important;
	}
}

h4,
h5,
h6 {
	font: {
		family: $font-family-exo;
		weight: $font-weight-bold;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: $spacer;
	line-height: 1.5;
}

.bg-dark {
	h4 {
		color: $icky-green;
	}

	h6 {
		color: $white !important;

		&::after {
			border-color: white !important;
		}
	}
}

p {
	font-size: $font-size-base * 1.125;
	font-weight: $font-weight-regular;
}

// img
img {
	max-width: 100%;
	height: auto;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

a {
	font-size: $font-size-base_sm;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

//responsive
.main {
	section {
		.container {
			@include media-breakpoint-down(sm) {
				padding: 0 25px;
			}

			.container-holder {
				@include media-breakpoint-down(sm) {
					margin: 0;
				}
			}
		}
	}
}

//hide
.hide {
	display: none;
}

//gallery
.gallery {
	position: relative;
	z-index: 10;

	&-item {
		padding: 0 ($grid-gutter-width / 6) !important;

		.gallery-link {
			.gallery-img, .gallery-caption {
				border-radius: 4px;
			}
		}
	}
}

ul, ol {
	list-style: initial;
}

.navbar-nav {
	list-style: none;
}
