.mini-sab {
	background-color: $icky-green;
	position: relative;
	&::before {
		content: "";
		background: url("/images/leaf.svg") repeat !important;
		background-size: 100%;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		opacity: 0.3;
	}
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		.container-holder {
			z-index: 1;
			position: relative;
			margin: -15px 0 0 0;
			padding: ($grid-gutter-width / 3) ($grid-gutter-width / 3) 0;
			border-radius: 45px;
			background: $secondary;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			// below eyecatcher
			@include media-breakpoint-up(sm) {
				padding: ($grid-gutter-width / 3) 0 ($grid-gutter-width);
			}
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
}
