.btn {
    display: inline-flex;
    align-items: center;
    padding: ($grid-gutter-width / 5) ($grid-gutter-width / 2.5);
    font-size: $font-size-base;
    font-family: $font-family-exo;
    font-weight: $font-weight-regular;
    border-radius: 3px;
    color: $white;
    text-decoration: none !important;
    transition: 0.3s;
    /* btn-primary */
    &.btn-primary {
        display: flex !important;
        @extend .justify-content-center;
        background: $primary;
        border-color: $primary;
        color: $white;
        font-family: $font-family-special-elite;
        padding: ($grid-gutter-width / 2.5) ($grid-gutter-width / 1.5);
        @include media-breakpoint-up(sm) {
            padding: ($grid-gutter-width / 1.875) ($grid-gutter-width);
            width: max-content;
            margin: auto
        }
        &:hover {
            background: darken($color: $primary, $amount: 10%);
            border-color: $primary;
            color: $white;
        }
    }

    /* btn-outline-primary */
    &.btn-outline-primary {
        color: $primary;
        font-family: $font-family-special-elite;
        padding: ($grid-gutter-width / 2.5) ($grid-gutter-width / 1.5);
        @include media-breakpoint-up(sm) {
            padding: ($grid-gutter-width / 1.875) ($grid-gutter-width);
        }
        &:hover {
            color: $white;
        }
    }

    /* btn-secondary */
    &.btn-secondary {
        border-radius: 2px;
        &:hover {
        }
    }

    /* btn-outline-secondary */
    &.btn-outline-secondary {
        border-radius: 2px;
        color: $secondary;
        &:hover {
            color: $white;
        }
    }
}

/*  btn-back
    ========================================================================== */
.btn-back {
    color: $white;
    font-size: $font-size-base * 1.125;
    font-family: $font-family-special-elite;
    font-weight: $font-weight-regular;
    margin-left: ($grid-gutter-width * 1.66);
    text-decoration: none;
    &::before {
        content: url("/images/arrow_left.svg");
        position: absolute;
        left: 10px;
        top: 4px;
    }
}

/*  btn-down
    ========================================================================== */
.btn-down {
    @extend .btn;
    @extend .btn-primary;
    position: absolute;
    left: 10%;
    right: 0;
    top: 0;
    transform: translate(0,-90px);
    z-index: 1;
    width: max-content;
    @include media-breakpoint-up(sm) {
        left: 50%;
        transform: translate(10%,-90px);
    }
    @include media-breakpoint-up(lg) {
        transform: translate(50%,-90px);
    }
}

/*  bg-dark
    ========================================================================== */
.bg-dark {
    .btn {
        /* btn-secondary */
        &.btn-secondary {
            border-color: $white;
        }
        /* btn-outline-primary */
        &.btn-outline-primary {
            color: $white;
            border-color: $white;
            &:hover {
                background-color: transparent;
                box-shadow: $box-shadow;
            }
        }
        /* btn-outline-secondary */
        &.btn-outline-secondary {
            color: $white;
            border-color: $white;
            &:hover {
                box-shadow: $box-shadow;
            }
        }
    }
}
